import { Flex, Text } from '@chakra-ui/react'
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { SimpleScannerDashboard } from 'layouts/dash/types'
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import api from 'services/api'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)

type TResultTemperamentos = {
  question: number
  count: number
  label: string
  color: string
}

const options: any = {
  responsive: true,
  layout: {
    padding: {
      top: 40,
    },
  },
  plugins: {
    title: {
      display: false,
      text: 'Temperamentos',
      position: 'bottom',
      color: 'black',
      font: {
        weight: '800',
        size: '20px',
      },
    },
    legend: {
      display: false,
    },
    datalabels: {
      labels: {
        title: {
          formatter: (value: any, context: any) => {
            const tempQty = context.dataset.data[context.dataIndex]
            const tempLabel = context.chart.data.labels[context.dataIndex]
            return tempQty
          },
          anchor: 'end',
          align: 'top',
          color: 'black',
          font: {
            weight: '700',
            size: '22px',
          },
        },
      },
    },
  },
  scales: {
    y: {
      display: false,
    },
    x: {
      grid: {
        display: false,
        borderWidth: 2,
        borderColor: '#c2bfbe',
      },
      ticks: {
        font: {
          weight: '700',
          size: '13px',
        },
      },
    },
  },
}

export default function TemperamentosChart(props: SimpleScannerDashboard) {
  const { userId, chartRefs, scanner = false } = props
  const [data, setData] = useState<TResultTemperamentos[]>([])

  useEffect(() => {
    if (!userId || userId === '') return setData([])

    api
      .post(`tableresponse/evaluations/`, { evaluations: [userId] })
      .then(({ data }) => setData(data))
      .catch(() => null)
  }, [userId])

  if (!userId || userId === '' || !data.length) return null

  return (
    <Flex width="100%" justifyContent="center" alignItems="center" flexDirection="column" ref={chartRefs?.[0]}>
      {!scanner ? <Text marginLeft={'0.3125rem'}>{`${data[0]?.label ?? ''} | ${data[1]?.label ?? ''}`}</Text> : null}
      <Flex maxWidth="31.25rem" width="100%">
        <Bar
          data={{
            labels: data.map((d) => d.label),
            datasets: [
              {
                data: data.map((d) => d.count),
                backgroundColor: data.map((d) => d.color),
              },
            ],
          }}
          options={options}
        />
      </Flex>
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Text fontSize="1.25rem" fontWeight="800" marginTop="1.25rem">
          Resultado do teste dos Temperamentos
        </Text>
        {!scanner ? (
          <Text
            fontWeight="400"
            align="center"
            marginTop="0.625rem"
            fontSize="0.875rem"
            maxWidth="500"
            color="gray.500"
            as="i"
          >
            O gráfico apresenta a pontuação obtida no teste. A maior pontuação, representada no primeiro gráfico à
            esquerda, é o temperamento dominante. A segunda maior pontuação, representada pelo segundo gráfico, é o
            temperamento secundário.
          </Text>
        ) : (
          <Text marginLeft={'0.3125rem'}>{`${data[0]?.label ?? ''} | ${data[1]?.label ?? ''}`}</Text>
        )}
      </Flex>
    </Flex>
  )
}
