import { Button } from '@chakra-ui/button'
import { Document, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer'
import { Cover, ReportFooter } from 'components/Reports'
import { pageStyle } from 'components/Reports/styles'
import { IScannerRespondentGroup } from 'pages/Dashboards/Scanner/Scanner/types'
import { FaPrint } from 'react-icons/fa'

const styles = StyleSheet.create({
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderLeft: '1px solid black',
  },
  tableColumn: {
    width: '30%',
    fontSize: '8px',
    fontWeight: 'bold',
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
  },
  tableColumnText: {
    padding: '10px 16px',
  },
  tableColumnHead: {
    width: '30%',
    padding: '10px 16px',
    fontSize: '10px',
    fontFamily: 'Lato Bold',
    color: '#404040',
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
  },
})

const TypeEvaluation: { [key: string]: string } = {
  '3': 'Alpha',
  '4': 'Via',
  '5': 'Temperamentos',
  '6': 'Motivacional',
  '9': 'Objeções',
}

interface IProps {
  companyName?: string
  courseName?: string
  respondents: IScannerRespondentGroup[]
}

export default function ReportRespondentsPdf(props: IProps) {
  const { companyName, respondents, courseName } = props

  return (
    <PDFDownloadLink
      document={
        <Document>
          <Cover
            title="RELATÓRIO RESPONDENTES"
            companyIntro={companyName ? 'Empresa' : 'Treinamento'}
            companyName={companyName || courseName || ''}
          />
          <Page size="A4" wrap style={pageStyle.page}>
            <Text style={pageStyle.title}>RELATÓRIO</Text>
            <View
              style={{
                borderTop: '1px solid black',
                width: '70%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '20px',
              }}
            >
              <View style={styles.tableRow}>
                <Text
                  style={{
                    ...styles.tableColumnHead,
                    width: '10%',
                  }}
                >
                  N°
                </Text>
                <Text style={styles.tableColumnHead}>Nome</Text>
                <Text style={styles.tableColumnHead}>Avaliação</Text>
                <Text style={styles.tableColumnHead}>Concluído</Text>
              </View>
              <View wrap>
                {respondents.map((user, index: number) => {
                  return (
                    <View key={index} style={styles.tableRow} minPresenceAhead={4}>
                      <View
                        style={{
                          ...styles.tableColumn,
                          width: '10%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                          backgroundColor: user.evaluations.find((ev) => !ev.done) ? '#ffff01' : '#93d04f',
                        }}
                      >
                        <Text style={styles.tableColumnText}>{index + 1}</Text>
                      </View>
                      <View
                        style={{
                          ...styles.tableColumn,
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                          backgroundColor: user.evaluations.find((ev) => !ev.done) ? '#ffff01' : '#93d04f',
                        }}
                      >
                        <Text style={styles.tableColumnText}>{user.name}</Text>
                      </View>
                      <View style={{ ...styles.tableColumn, borderBottom: 0 }}>
                        {user.evaluations.map((ev) => (
                          <Text
                            key={ev.type_evaluation}
                            style={{
                              ...styles.tableColumnText,
                              backgroundColor: ev.done ? '#93d04f' : '#ffff01',
                              borderBottom: '1px solid black',
                            }}
                          >
                            {TypeEvaluation[ev.type_evaluation]}
                          </Text>
                        ))}
                      </View>
                      <View style={{ ...styles.tableColumn, borderBottom: 0 }}>
                        {user.evaluations.map((ev) => (
                          <Text
                            key={ev.type_evaluation}
                            style={{
                              ...styles.tableColumnText,
                              backgroundColor: ev.done ? '#93d04f' : '#ffff01',
                              borderBottom: '1px solid black',
                            }}
                          >
                            {ev.done ? 'Sim' : 'Não'}
                          </Text>
                        ))}
                      </View>
                    </View>
                  )
                })}
              </View>
            </View>

            <ReportFooter />
          </Page>
        </Document>
      }
      fileName={`Relatório de Respondentes - ${companyName || courseName || ''}`}
    >
      {({ url, loading }) => {
        return (
          <Button
            variant="outline"
            w="9.8125rem"
            h="8"
            fontSize="0.75rem"
            leftIcon={<FaPrint size={18} />}
            isLoading={loading || !url}
          >
            Respondentes PDF
          </Button>
        )
      }}
    </PDFDownloadLink>
  )
}
