import { Flex, Text } from '@chakra-ui/react';
import { SimpleScannerDashboard } from 'layouts/dash/types';
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import AlphaChartPiece from './Chart/AlphaChartPiece';

const AlphaChart: React.FC<SimpleScannerDashboard> = ({ userId, chartRefs, scanner = false }) => {
  const [data, setData] = useState<number[]>([]);

  useEffect(() => {
    if (!userId || userId === '') return setData([]);

    api
      .post(`tableresponse/evaluations/`, { evaluations: [userId] })
      .then(({ data }) => setData(data))
      .catch(() => null);
  }, [userId]);

  if (!userId || userId === '' || !data.length) return null;

  return (
    <Flex
      justifyItems="center"
      alignItems="center"
      flexDirection="column"
      ref={chartRefs?.[0]}
      width="100%"
      height="min-content"
      paddingTop="0.625rem"
    >
      <Flex backgroundImage="linear-gradient(180deg,white 2.5rem,  #e1f3e9 2.5rem 56%, #fadedd 55%)">
        <Flex flexDirection="column" margin="2.5rem 2.5rem 0 1.25rem">
          <p style={{ height: '50%', fontSize: '1.25rem' }}>Forças</p>
          <p style={{ height: '50%', fontSize: '1.25rem' }}>Riscos</p>
        </Flex>
        <Flex justifyContent="space-between" flexGrow={1} gap="1.25rem">
          <AlphaChartPiece
            title={data[0] >= 50 || data[1] >= 50 ? 'ALPHA' : 'BETA'}
            positive={data[0]}
            negative={data[1]}
          />
          <AlphaChartPiece title="COMANDANTE" positive={data[2]} negative={data[3]} />
          <AlphaChartPiece title="VISIONÁRIO" positive={data[4]} negative={data[5]} />
          <AlphaChartPiece title="ESTRATEGISTA" positive={data[6]} negative={data[7]} />
          <AlphaChartPiece title="EXECUTOR" positive={data[8]} negative={data[9]} />
        </Flex>
      </Flex>
      <Text fontWeight="800" align="center" marginTop="0.625rem" fontSize="1.25rem">
        Resultado do teste Alpha
      </Text>
      {!scanner ? (
        <Text
          as="i"
          fontWeight="400"
          align="center"
          marginTop="0.625rem"
          fontSize="0.875rem"
          maxWidth="700"
          color="gray.500"
        >
          A barra verde indica sua pontuação percentual em pontos fortes alpha, enquanto a barra vermelha mostra sua
          pontuação percentual em riscos alpha. Classificado como alpha se valores percentuais forem iguais ou maiores
          que 50% na força ou no risco; caso contrário, não alpha. Outros quatro gráficos indicam seu estilo alpha
          predominante: comandante, visionário, estrategista e executor. Os dois com maior percentual de resposta, na
          força ou no risco, correspondem ao seu perfil predominante.
        </Text>
      ) : null}
    </Flex>
  );
};

export default AlphaChart;
