import { Button, Flex, Heading, useDisclosure } from '@chakra-ui/react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import EvaluationSelector from 'components/EvaluationSelector';
import { DesempenhoEquipeReport } from 'components/Reports/DesempenhoEquipe';
import ReportLink from 'components/Reports/Drawer/ReportLink';
import ReportModal from 'components/Reports/Drawer/ReportModal';
import { toPng } from 'html-to-image';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { FaPrint } from 'react-icons/fa';
import { useParams } from 'react-router';
import api from 'services/api';
import { useEvaluationSelectorStore } from 'stores/evaluationSelector';
import { useLoadingStore } from 'stores/loadingStore';
import { generateImgLink } from 'utils/generateImgLink';
import { sleep } from 'utils/sleep';
import { ConsolidatedChart, ConsolidatedReport, Report, SimpleDashboard, SimpleScannerDashboard } from './types';

interface IProps {
  title: string;
  Chart: React.FC<SimpleDashboard> | React.FC<SimpleScannerDashboard>;
  ConsolidatedChart?: React.FC<ConsolidatedChart>;
  Report?: React.FC<Report>;
  ConsolidatedReport?: React.FC<ConsolidatedReport>;
  isSector?: boolean;
}

function ScannerDash(props: IProps): JSX.Element {
  const { Chart, ConsolidatedChart, title, ConsolidatedReport, isSector } = props;

  const setLoading = useLoadingStore((state) => state.setLoading);

  const { selectedEvaluations, selectedCompanies, selectedSectors } = useEvaluationSelectorStore();

  const { id: evaluationType } = useParams<{ id: string }>();

  const responses = useRef<any>(undefined);
  const [links, setLinks] = useState<string[]>([]);
  const [loadedLinks, setLoadedLinks] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const chartRefs = [useRef<any>(), useRef<any>(), useRef<any>(), useRef<any>()];

  const selectedIds = useMemo(() => {
    if (evaluationType === '14') {
      return selectedCompanies.map((company) => company.id || '');
    }
    if (isSector) {
      return selectedSectors.map((sector) => sector.id);
    }
    return selectedEvaluations.map((evaluation) => evaluation.evaluation_id);
  }, [selectedEvaluations, selectedSectors, isSector, selectedCompanies]);

  function getName() {
    let first = '';
    if (isSector) {
      first = selectedSectors?.length > 1 ? 'Vários Setores' : selectedSectors?.[0]?.sector_name ?? 'Nenhum Setor';
    } else {
      first =
        selectedEvaluations?.length > 1 ? 'Várias Avaliações' : selectedEvaluations?.[0]?.name ?? 'Nenhuma Avaliação';
    }
    const second =
      selectedCompanies?.length > 1 ? 'Várias Empresas' : selectedCompanies?.[0]?.commercial_name ?? 'Nenhuma Empresa';
    return `${title} - ${first} - ${second}`;
  }

  async function handleDownloadPDF() {
    try {
      setLoading(true);
      const body: any = {
        evaluation_type: evaluationType,
        company_ids: selectedCompanies.map((company) => company.id),
      };

      switch (evaluationType) {
        case '12':
          body.target_ids = selectedEvaluations.map((ev) => ev.evaluation_id);
          break;
        default:
          body.target_ids = selectedEvaluations.map((evaluation) => evaluation.evaluation_id);
          body.selected_ids = selectedIds;
          break;
      }

      const res = await api.post('tableresponse/report/download', body, { responseType: 'blob' });

      const fileURL = window.URL.createObjectURL(res.data);

      const alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = `Avaliação ${getName()}.pdf`;
      alink.click();
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }

  async function handleDownloadGraph() {
    const populated = chartRefs.filter((ref) => ref.current);
    populated.forEach(async (ref, index) => {
      if (!ref || !ref.current) return;
      const element = ref.current;
      const link = await generateImgLink(element);
      link.download = `Gráfico ${getName()}${populated.length > 1 ? ` - ${index + 1}` : ''}.png`;
      link.click();
    });
  }

  useEffect(() => {
    if (!isOpen) return;
    setLoadedLinks(false);
    const some = async () => {
      await sleep(2);
      const test = chartRefs.map((ref) => {
        if (!ref.current) return '';
        return toPng(ref.current, {
          cacheBust: true,
          quality: 1.0,
          backgroundColor: 'white',
          fontEmbedCSS: 'sans-serif',
        });
      });

      const res = await Promise.all(test);

      setLinks(res);
      setLoadedLinks(true);
      // res.forEach((data) => {
      //   const link = document.createElement('a')
      //   link.download = `testing.png`
      //   link.href = data
      //   link.click()
      // })
    };
    some();
  }, [isOpen]);

  return (
    <>
      <Flex w="100%" h="calc(100vh - 4rem)" flexDirection="column" paddingLeft={'1rem'}>
        <Flex flexWrap={'wrap'} py={2}>
          <Heading fontSize="lg" color="gray.700">
            {title}
          </Heading>

          <Button
            variant="outline"
            w="9.8125rem"
            h="8"
            margin="0 1.5rem 0 auto"
            fontSize="0.75rem"
            leftIcon={<FaPrint size={18} />}
            onClick={onOpen}
            disabled={!selectedIds?.length}
          >
            Imprimir
          </Button>
        </Flex>

        <EvaluationSelector />

        <Flex width="100%" height="100%" overflow="hidden" paddingTop="1.25rem" paddingBottom="1.25rem">
          {selectedIds.length === 1 ? (
            <Chart chartRefs={chartRefs} userId={selectedIds[0]} reportOpen={isOpen} scanner={false} />
          ) : selectedIds.length > 1 && ConsolidatedChart ? (
            <ConsolidatedChart evaluations={selectedIds} responses={responses} chartRefs={chartRefs} />
          ) : null}
        </Flex>
      </Flex>
      <ReportModal title={`Impressão ${title}`} isOpen={isOpen} onClose={onClose}>
        <Flex flexDirection="column" gap="2">
          {/* Download Graph */}
          <Button
            variant="outline"
            w="9.8125rem"
            h="8"
            fontSize="0.75rem"
            leftIcon={<AiOutlineDownload />}
            disabled={!selectedIds?.length}
            onClick={handleDownloadGraph}
          >
            Download Gráfico
          </Button>

          {/* Download PDF*/}
          {selectedIds.length === 1 && (
            <>
              {evaluationType === '7' ? (
                <>
                  {loadedLinks ? (
                    <PDFDownloadLink
                      document={
                        <DesempenhoEquipeReport
                          reportTarget={{
                            company: selectedCompanies?.[0]?.commercial_name ?? '',
                            name: selectedEvaluations?.[0]?.name ?? '',
                          }}
                          links={links}
                        />
                      }
                      fileName="relatório.pdf"
                    >
                      {({ loading, url }) => (
                        <Button
                          variant="outline"
                          w="9.8125rem"
                          h="8"
                          fontSize="12px"
                          leftIcon={<FaPrint size={18} />}
                          isLoading={loading || !url}
                        >
                          Imprimir Relatório
                        </Button>
                      )}
                    </PDFDownloadLink>
                  ) : (
                    <Button
                      variant="outline"
                      w="9.8125rem"
                      h="8"
                      fontSize="12px"
                      leftIcon={<FaPrint size={18} />}
                      isLoading={true}
                    >
                      Imprimir Relatório
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  variant="outline"
                  w="9.8125rem"
                  h="8"
                  fontSize="0.75rem"
                  leftIcon={<AiOutlineDownload />}
                  disabled={!selectedIds?.length}
                  onClick={handleDownloadPDF}
                >
                  Download Relatório
                </Button>
              )}
            </>
          )}

          {/* Download PDF Consolidated*/}
          {selectedIds.length > 1 &&
            (ConsolidatedReport ? (
              <ReportLink
                Report={ConsolidatedReport}
                title={title}
                chartRefs={chartRefs}
                selectedCompany={selectedCompanies[0].commercial_name ?? ''}
                selectedUserName={selectedEvaluations?.[0]?.name ?? ''}
                responses={responses}
              />
            ) : (
              <Button
                variant="outline"
                w="9.8125rem"
                h="8"
                fontSize="0.75rem"
                leftIcon={<AiOutlineDownload />}
                disabled={!selectedIds?.length}
                onClick={handleDownloadPDF}
              >
                Download Relatório
              </Button>
            ))}
        </Flex>
      </ReportModal>
    </>
  );
}

export default ScannerDash;
