import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import { ChartData } from 'chart.js'
import { SimpleDashboardConsolidated } from 'layouts/dash/types'
import React, { useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import api from 'services/api'
interface ITemperamentDefault {
  [key: string]: number
}

interface ITemperamentosSheetData {
  name: string
  primary: string
  secondary: string
}

const temperamentDefault: ITemperamentDefault = {
  Sanguíneo: 0,
  Colérico: 0,
  Melancólico: 0,
  Fleumático: 0,
}

interface IUsersConsolidatedTempResponses {
  [key: string]: {
    name: string
    first: string
    second: string
  }
}

function TemperamentosConsolidated(props: SimpleDashboardConsolidated) {
  const { evaluations, chartRefs, responses, evaluationData } = props
  const [barData, setBarData] = useState<ChartData<'bar', number[], unknown>>({
    datasets: [],
  })
  const [userSheetData, setUserSheetData] = useState<ITemperamentosSheetData[]>([])
  const toast = useToast()

  function handleConsolidatedData(data: any) {
    const primary = { ...temperamentDefault }
    const secondary = { ...temperamentDefault }
    const userSheetTemp: ITemperamentosSheetData[] = []

    const evaluations = Object.keys(data)

    evaluations.forEach((u: string) => {
      if (data[u]) {
        userSheetTemp.push({
          name: data[u].name,
          primary: data[u].first,
          secondary: data[u].second,
        })
        primary[data[u].first] += 1
        secondary[data[u].second] += 1
      }
    })

    responses.current = userSheetTemp

    setUserSheetData(userSheetTemp)

    setBarData({
      labels: ['Sanguíneo', 'Colérico', 'Melancólico', 'Fleumático'],
      datasets: [
        {
          label: 'Primário',
          data: Object.values(primary),
          backgroundColor: ['#BF0000', '#F3B507', '#0D0D0D', '#2957A4'],
          minBarLength: 7,
        },
        {
          label: 'Secundário',
          data: Object.values(secondary),
          backgroundColor: ['#bf000090', '#F3B50790', '#0D0D0D90', '#2957A490'],
          minBarLength: 7,
        },
      ],
    })
  }

  useEffect(() => {
    async function fetchConsolidated() {
      if (evaluationData) return
      if (!evaluations || !evaluations.length) return
      if (!evaluations.length) {
        setBarData({
          datasets: [],
        })
        return
      }
      try {
        const { data } = await api.post<IUsersConsolidatedTempResponses>(`tableresponse/temperamentos/consolidated`, {
          evaluations: evaluations,
        })
        handleConsolidatedData(data)
      } catch (err) {
        toast({
          status: 'error',
          description: 'Ocorreu um erro no servidor, entre em contato com os desenvolvedores',
          duration: 3000,
          isClosable: true,
        })
      }
    }
    fetchConsolidated()
  }, [evaluations])

  useEffect(() => {
    if (evaluationData) {
      handleConsolidatedData(evaluationData)
    }
  }, [evaluationData])

  const barOptions: any = useMemo(() => {
    return {
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          top: 40,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (t: any) => {
              const total = t.dataset.data.reduce((acc: number, a: number) => acc + a, 0)
              return `${((t.raw / total) * 100).toFixed(1)}%`
            },
          },
        },
        datalabels: {
          labels: {
            value: {
              formatter: (value: any) => {
                return [`${((value / userSheetData.length) * 100 || 0).toFixed(0)}%`, value]
              },
              anchor: 'end',
              align: 'top',
              textAlign: 'center',
              offset: -5,
              color: 'black',
              font: {
                weight: 500,
                size: 13,
              },
            },
          },
        },
      },
      scales: {
        y: {
          display: false,
        },
        x: {
          grid: {
            display: false,
            borderWidth: 2,
            borderColor: '#c2bfbe',
          },
        },
      },
    }
  }, [userSheetData])

  if (!userSheetData.length) return null

  return (
    <Flex width="100%" height="100%" maxHeight="34.375rem" justifyContent="space-evenly">
      <Box margin="auto 0" height="80%">
        <Flex flexDirection="column" alignItems="center" ref={chartRefs?.[0]} padding="0.625rem" height="100%">
          <Text fontSize="1.25rem" fontWeight="700" textAlign="center">
            Resultado Consolidado do teste dos Temperamentos
          </Text>
          <Text fontSize="0.875rem" fontWeight="700" textAlign="center">
            Colaboradores selecionados: {userSheetData.length}
          </Text>
          <Box height="100%" width="100%">
            <Bar height="100%" data={barData} options={barOptions} />
          </Box>
          <Text
            as="i"
            fontWeight="400"
            align="center"
            marginTop="0.625rem"
            fontSize="0.875rem"
            maxWidth="500"
            color="gray.500"
          >
            Os números em cada coluna indicam a quantidade de pessoas com cada perfil, em valores percentuais e
            absolutos. Em cada tipo de temperamento, temos duas colunas, sendo a coluna 1, com cor mais intensa,
            referente ao perfil primário, e a coluna 2, com cor mais suave, ao perfil secundário.
          </Text>
        </Flex>
      </Box>
      {!evaluationData && (
        <Box
          boxShadow={'#7a7a7a 0rem 0rem 0.0625rem'}
          maxWidth="37.5rem"
          borderRadius={'0.3125rem'}
          overflowY="auto"
          maxHeight="min(37.5rem, 100%)"
          alignSelf="flex-start"
          marginTop="auto"
          marginBottom="auto"
          style={{ scrollbarWidth: 'thin' }}
        >
          <Table variant="striped">
            <Thead position="sticky" top="0">
              <Tr bgColor={'white'}>
                <Th>Nome</Th>
                <Th>Primário</Th>
                <Th>Secundário</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userSheetData.map((user, index) => (
                <Tr key={index}>
                  <Td>{user.name}</Td>
                  <Td>{user.primary}</Td>
                  <Td>{user.secondary}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Flex>
  )
}
export default TemperamentosConsolidated
