import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import { Cover, ReportFooter, ReportHeader } from 'components/Reports'
import React from 'react'
import '../fonts'
import { pageStyle } from '../styles'

type IProps = {
  reportTarget: {
    name: string
    company: string
  }
  links?: string[]
}

export const DesempenhoEquipeReport = (props: IProps) => {
  const { reportTarget, links } = props
  const pageHeader = 'Avaliação Desempenho Líder vs Equipe'
  return (
    <Document>
      <Cover
        title={`resultados \n avaliação desempenho \n Líder vs Equipe`}
        evaluatedName={reportTarget.name}
        companyName={reportTarget.company}
      />

      {/* intro */}
      <Page size="A4" wrap style={{ ...pageStyle.page, lineHeight: 1.3 }}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>APRESENTAÇÃO</Text>

        <View style={{ width: '80%', margin: '0 auto' }}>
          <Text style={pageStyle.paragraph}>
            A avaliação de desempenho do líder versus da equipe é uma ferramenta que oferece ao líder uma análise
            comparativa tanto da performance de trabalho da equipe quanto de sua própria atuação. Por meio de gráficos
            criteriosamente elaborados, é possível identificar de forma clara as proximidades ou discrepâncias entre o
            líder e o time, revelando aspectos importantes sobre a sinergia de trabalho e a contribuição do líder para o
            desempenho coletivo do grupo.
          </Text>

          <Text style={pageStyle.paragraph}>
            A avaliação é composta por 20 afirmativas, as quais foram respondidas com notas de 0 a 10, onde 0 refere-se
            a &quot;discordo totalmente&quot; e 10 a &quot;concordo totalmente&quot; As questões de 1 a 10 correspondem
            à avaliação do desempenho da equipe, enquanto as questões de 11 a 20 referem-se à avaliação do desempenho do
            líder. É importante destacar que o próprio líder respondeu a avaliação, tornando a visão dos resultados
            centrada em sua percepção sobre o desempenho do time e de si mesmo.
          </Text>
          <Text style={pageStyle.paragraph}>
            Com base nas respostas obtidas, três gráficos foram construídos e serão apresentados na sequência. Eles
            ilustram o desempenho da equipe e do líder, revelando áreas de destaque e oportunidades de aprimoramento.
          </Text>
        </View>

        <ReportFooter />
      </Page>

      {/* 2 */}
      <Page size="A4" wrap style={{ ...pageStyle.page, lineHeight: 1.3 }}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>RESULTADO:</Text>

        <div style={{ ...pageStyle.chartImage, width: '90%' }}>
          <Image src={links?.[2] || ''} />
        </div>
        <View
          style={{
            width: '80%',
            margin: '0 auto',
          }}
        >
          <Text style={pageStyle.paragraph}>
            O gráfico acima apresenta as notas atribuídas pelo líder para seu próprio desempenho (coluna cinza) e para o
            desempenho da equipe de trabalho (coluna amarela). As notas variam de 0 a 10 e indicam o nível de satisfação
            do líder com cada aspecto avaliado. É importante destacar que uma diferença significativa entre as notas
            pode indicar uma falta de alinhamento entre o líder e a equipe, o que pode comprometer o desempenho geral.
          </Text>

          <Text style={{ ...pageStyle.plain, marginTop: 14 }}>Os 10 pontos, avaliados foram:</Text>
          <Text style={pageStyle.plain}>1. O desempenho e os resultados entregues</Text>
          <Text style={pageStyle.plain}>2. A clareza dos objetivos</Text>
          <Text style={pageStyle.plain}>
            3. O relacionamento interpessoal (comunicação, fluxo de informação, respeito mútuo)
          </Text>
          <Text style={pageStyle.plain}>
            4. A maturidade e assertividade para tratar conflitos, problemas e divergências profissionais.
          </Text>
          <Text style={pageStyle.plain}>5. O uso de indicadores para avaliar os resultados.</Text>
          <Text style={pageStyle.plain}>6. O nível de competência (conhecimentos, habilidades e atitude).</Text>
          <Text style={pageStyle.plain}>7. A habilidade de levar, receber e usar ideias inovadoras.</Text>
          <Text style={pageStyle.plain}>8. A estrutura da equipe e posição do líder.</Text>
          <Text style={pageStyle.plain}>9. A organização e os processos de trabalho.</Text>
          <Text style={pageStyle.plain}>10. A motivação e o compromisso.</Text>
          <Text style={pageStyle.paragraph}>
            Ao comparar o desempenho do líder com o desempenho do time, é possível identificar se existe uma diferença
            significativa e, caso exista, em que tópico essa diferença é mais notável. Isso possibilitará a definição de
            medidas concretas para aprimorar o desempenho da equipe, ao mesmo tempo em que fornecerá ao líder
            informações valiosas para desenvolver suas habilidades de liderança de forma mais efetiva.
          </Text>
        </View>
        <ReportFooter />
      </Page>

      {/* 0 */}
      <Page size="A4" wrap style={{ ...pageStyle.page, lineHeight: 1.3 }}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>RESULTADO:</Text>

        <div style={{ ...pageStyle.chartImage, width: '50%' }}>
          <Image src={links?.[0] || ''} />
        </div>
        <View
          style={{
            width: '80%',
            margin: '0 auto',
          }}
        >
          <Text style={pageStyle.paragraph}>
            O gráfico em questão apresenta uma comparação entre o desempenho do líder e da equipe de trabalho,
            representados pelas linhas cinza e amarela, respectivamente. As linhas percorrem quatro possíveis zonas de
            classificação: Insuficiente, Média, Boa e Excelente.
          </Text>
          <View
            style={{
              ...pageStyle.paragraph,
              marginLeft: 14,
              marginRight: 28,
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={{ marginRight: 14 }}>{'\u2022'}</Text>
              <Text style={pageStyle.plain}>
                Zona Insuficiente (notas de 0,0 a 4,0): Indica que tanto o líder quanto a equipe precisam melhorar para
                atingir as expectativas de desempenho.
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={{ marginRight: 14 }}>{'\u2022'}</Text>
              <Text style={pageStyle.plain}>
                Zona Média(notas de 4,1 a 6,0): Reflete um desempenho adequado, mas com espaço para aperfeiçoamentos e
                desenvolvimento contínuo.
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={{ marginRight: 14 }}>{'\u2022'}</Text>
              <Text style={pageStyle.plain}>
                Zona Boa(notas de 6,1 a 8,0): Mostra que o líder e a equipe estão alcançando resultados satisfatórios,
                mas ainda podem buscar melhorias.
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={{ marginRight: 14 }}>{'\u2022'}</Text>
              <Text style={pageStyle.plain}>
                Zona Excelente(notas de 8,1 a 10,0): Representa um desempenho notável, tanto do líder quanto da equipe,
                revelando liderança efetiva e colaboração produtiva.
              </Text>
            </View>
          </View>

          <Text style={pageStyle.paragraph}>
            Ao observar o gráfico, é possível identificar em qual zona prevaleceu o desempenho do líder e da equipe,
            além de perceber se há discrepâncias ou similaridades entre as zonas. Podemos notar, por exemplo, se o líder
            se destaca positivamente em relação à equipe ou se ambos estão trabalhando em harmonia, alcançando
            resultados. Com base nas informações fornecidas pelas linhas e zonas, é possível identificar oportunidades
            de crescimento e aprimoramento tanto para o líder quanto para a equipe, visando sempre alcançar resultados
            superiores. A análise desses dados pode guiar a implementação de estratégias para fortalecer a liderança,
            melhorar a colaboração e potencializar o desempenho geral da equipe, resultando em uma organização mais
            bem-sucedida e produtiva.
          </Text>
        </View>
        <ReportFooter />
      </Page>

      {/* 1 */}
      <Page size="A4" wrap style={{ ...pageStyle.page, lineHeight: 1.3 }}>
        <ReportHeader title={pageHeader} />
        <Text style={pageStyle.title}>RESULTADO:</Text>

        <div style={{ ...pageStyle.chartImage, width: '50%' }}>
          <Image src={links?.[1] || ''} />
        </div>
        <View
          style={{
            width: '80%',
            margin: '0 auto',
          }}
        >
          <Text style={pageStyle.paragraph}>
            No gráfico, são atribuídas pontuações gerais tanto para o líder quanto para a equipe, posicionando esses
            resultados nos eixos horizontal e vertical, respectivamente. Com base nessa representação visual, é possível
            identificar a área na qual os resultados se encontram: Área A, B, C ou D, cada uma com seu significado
            específico. Essa análise espacial permite uma compreensão clara da dinâmica entre a sinergia grupal e a
            contribuição individual, auxiliando no direcionamento de ações e estratégias para impulsionar o desempenho
            do grupo.
          </Text>
          <Text style={pageStyle.paragraph}>Interpretação das Áreas:</Text>
          <View style={pageStyle.paragraph}>
            <Text style={{ fontFamily: 'Lato Bold' }}>Área A:</Text>
            <Text>
              Essa área representa a sinergia de alta performance da equipe, na qual os membros trabalham em harmonia e
              contam com a efetiva contribuição do líder para alcançar o sucesso. Aqui, tanto o desempenho coletivo
              quanto a liderança são bem-sucedidos, evidenciando uma equipe coesa e um líder que desempenha um papel
              inspirador e motivador. A presença nesta área indica um ambiente propício para resultados expressivos.
            </Text>
          </View>
          <View style={pageStyle.paragraph}>
            <Text style={{ fontFamily: 'Lato Bold' }}>Área B:</Text>
            <Text>
              Nessa área, a equipe está alcançando alta performance, mas o líder não está contribuindo de forma
              adequada. Caso o desempenho do líder esteja consideravelmente abaixo do esperado, ele pode estar
              representando um peso para a equipe, influenciando negativamente nos resultados. Essa situação exige
              atenção para que o líder possa se aprimorar e acompanhar o ritmo do time.
            </Text>
          </View>
          <View style={pageStyle.paragraph} wrap={false}>
            <Text style={{ fontFamily: 'Lato Bold' }}>Área C:</Text>
            <Text>
              Aqui, a equipe não apresenta um bom desempenho geral, mas o líder considera sua contribuição significativa
              para os resultados alcançados. Se o líder possui um desempenho muito superior ao da equipe, pode haver um
              sentimento de que ele é o único a se sacrificar pelos resultados, sugerindo uma desconexão entre o líder e
              a equipe.
            </Text>
          </View>
          <View style={pageStyle.paragraph}>
            <Text style={{ fontFamily: 'Lato Bold' }}>Área D:</Text>
            <Text>
              Essa área indica que o grupo de trabalho não está funcionando como uma equipe coesa. Cada indivíduo pode
              estar focado apenas em seus objetivos individuais, resultando em falta de colaboração e sinergia entre os
              membros. Nesse contexto, a equipe pode não alcançar seu potencial máximo de desempenho.
            </Text>
          </View>
          <View style={pageStyle.paragraph}>
            <Text style={{ fontFamily: 'Lato Bold' }}>Considerações Finais:</Text>
            <Text>
              A análise da Sinergia Grupal vs. Contribuição Individual é fundamental para compreender o desempenho
              coletivo da equipe e o papel do líder nesse contexto. Essa ferramenta possibilita a identificação de
              pontos fortes e oportunidades de melhoria, promovendo um ambiente de trabalho mais eficiente e produtivo.
              Os resultados obtidos no gráfico oferecem insights valiosos para o desenvolvimento tanto do líder quanto
              da equipe, visando sempre maximizar a sinergia e a contribuição individual para alcançar resultados
              extraordinários.
            </Text>
          </View>
        </View>
        <ReportFooter />
      </Page>
    </Document>
  )
}
