import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  List,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import AlertRespondent from 'components/AlertRespondent';
import DocumentComponent from 'components/DocumentComponent';
import { Loading } from 'components/Loading';
import ReportDrawer from 'components/Reports/Drawer';
import { format, getYear } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useCallback, useEffect, useState } from 'react';
import { FaCheck, FaPrint } from 'react-icons/fa';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { MultiSelect } from 'react-multi-select-component';
import api from 'services/api';
import { useAuthStore } from 'stores/authStore';
import { ICompany } from '../../Scanner/Alpha/interfaces';
import * as questionsData from './questionsData';
import { User } from './types';

interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}

const BarChart360 = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const [companies, setCompanies] = useState<Option[]>([]);
  const [users, setUsers] = useState<Option[]>([]);
  const [versions, setVersions] = useState<Option[]>([]);

  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([]);
  const [selectedUser, setSelectedUser] = useState<Option[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<Option[]>([]);

  const [resQuestions, setResQuestions] = useState<any>([]);

  const [questionSelect, setQuestion] = useState(0);
  const [qttAll, setQttAll] = useState(0);

  const { user } = useAuthStore();

  useEffect(() => {
    if (user.type_account === 2) {
      setSelectedCompanies([
        {
          value: user.company_id,
          label: user.company_name ?? '',
        },
      ]);
      handleUsers(user.company_id ?? '');
    }
  }, [user]);

  const handleUsers = useCallback(async (companyId: string): Promise<void> => {
    api
      .get(`tableresponse/respondents/${companyId}/2`)
      .then((result) => {
        if (result.data.length > 0) {
          setUsers(
            result.data.map((user: User) => ({
              value: user.id,
              label: user.name,
            })),
          );
        }
      })
      .catch(() => null);
  }, []);

  const handleDetails = async (user: string, version: string, count: number) => {
    const getQuestionResponse = async (question: number) => {
      const result = await api.get(`tableresponse/evaluation360/${user}/${question}/${version}`);
      if (result?.data?.length > 0) {
        const resultMap: {
          positive: any[];
          negative: any[];
          neutral: any[];
          positiveQty: number;
          negativeQty: number;
          neutralQty: number;
          positivePercentage: string;
          negativePercentage: string;
          neutralPercentage: string;
        } = {
          positive: (questionsData as any)?.[`response${question}`]
            .filter((a: any) => a.type === 1)
            .map((a: any) => ({
              ...a,
              percent_response: '0%',
              qtt_response: '0',
              selected_auto: false,
            })),
          negative: (questionsData as any)?.[`response${question}`]
            .filter((a: any) => a.type === 2)
            .map((a: any) => ({
              ...a,
              percent_response: '0%',
              qtt_response: '0',
              selected_auto: false,
            })),
          neutral: (questionsData as any)?.[`response${question}`]
            .filter((a: any) => a.type === 0)
            .map((a: any) => ({
              ...a,
              percent_response: '0%',
              qtt_response: '0',
              selected_auto: false,
            })),
          positiveQty: 0,
          negativeQty: 0,
          neutralQty: 0,
          positivePercentage: '0%',
          negativePercentage: '0%',
          neutralPercentage: '0%',
        };

        result.data.forEach((op: any) => {
          const resultInt = (Number(op.qtt_response) * 100) / count;
          const resultString = resultInt.toFixed(0);
          const val = Number(op.response);
          const typeOp = (questionsData as any)[`response${question}`][val - 1].type;

          if (typeOp === 2) {
            const foundResponse = resultMap.negative.find((a) => a.value === op.response);
            foundResponse.qtt_response = op.qtt_response;
            foundResponse.percent_response = `${resultString}%`;
            foundResponse.selected_auto = op.selected_auto;
            resultMap.negativeQty += Number(op.qtt_response);
          }
          if (typeOp === 1) {
            const foundResponse = resultMap.positive.find((a) => a.value === op.response);
            foundResponse.qtt_response = op.qtt_response;
            foundResponse.percent_response = `${resultString}%`;
            foundResponse.selected_auto = op.selected_auto;
            resultMap.positiveQty += Number(op.qtt_response);
          }
          if (typeOp === 0) {
            const foundResponse = resultMap.neutral.find((a) => a.value === op.response);
            foundResponse.qtt_response = op.qtt_response;
            foundResponse.percent_response = `${resultString}%`;
            foundResponse.selected_auto = op.selected_auto;
            resultMap.neutralQty += Number(op.qtt_response);
          }
        });

        resultMap.negativePercentage = `${(resultMap.negativeQty * 100) / (count * 6)}`;
        resultMap.neutralPercentage = `${(resultMap.neutralQty * 100) / (count * 6)}`;
        resultMap.positivePercentage = `${(resultMap.positiveQty * 100) / (count * 6)}`;

        return resultMap;
      } else {
        throw new Error('Erro ao buscar dados');
      }
    };

    const getQuestionResponseOpen = async (question: number) => {
      const result = await api.get<{ qtt_response: string; response: string; selected_auto: string }[]>(
        `tableresponse/evaluation360/${user}/${question}/${version}`,
      );
      if (result?.data?.length > 0) {
        const resultMap: { self: string[]; evaluators: string[] } = {
          self: [],
          evaluators: [],
        };

        result.data.forEach((response) => {
          if (response.selected_auto) {
            resultMap.self.push(response.response);
          } else {
            resultMap.evaluators.push(response.response);
          }
        });
        return resultMap;
      } else {
        throw new Error('Erro ao buscar dados');
      }
    };

    try {
      const questionsPromises = [
        getQuestionResponse(1),
        getQuestionResponse(2),
        getQuestionResponse(3),
        getQuestionResponse(4),
        getQuestionResponse(5),
        getQuestionResponse(6),
        getQuestionResponse(7),
        getQuestionResponse(8),
        getQuestionResponse(9),
        getQuestionResponse(10),
        getQuestionResponseOpen(11),
        getQuestionResponseOpen(12),
        getQuestionResponseOpen(13),
        getQuestionResponseOpen(14),
      ];

      const questionsRes = await Promise.all(questionsPromises as any);

      setResQuestions(questionsRes);
    } catch (err) {
      return;
    }
  };

  const handleVersions = useCallback(async (id: string): Promise<void> => {
    api.get(`evaluation/versions/${id}/${id}/2`).then(({ data }) => {
      setVersions(
        data.map((version: any) => ({
          label: new Date(version.created_at).toLocaleDateString(),
          value: version.version,
        })),
      );
      if (!data.length) {
        setVersions([{ label: 'Última', value: 1 }]);
      }
    });
  }, []);

  const fetchAllCompanies = useCallback(async (): Promise<void> => {
    const fetched = await api.get('companies');
    const sortedCompanies = fetched.data.sort((a: ICompany, b: ICompany) => {
      return a.commercial_name.localeCompare(b.commercial_name);
    });
    if (fetched.data.length) {
      return setCompanies(
        sortedCompanies.map((comp: ICompany) => ({
          label: comp.commercial_name,
          value: comp.id,
        })),
      );
    }
  }, []);

  useEffect(() => {
    fetchAllCompanies();
  }, []);

  return (
    <>
      <Flex w="100%" maxW="100%" h="calc(100vh - 4rem)" overflow="auto" flexDirection="column" paddingLeft="0.625rem">
        <Flex py={2}>
          <Heading fontSize="lg" color="gray.700">
            Avaliação 360
          </Heading>
          <Box ml="auto">
            <AlertRespondent
              params={
                selectedUser.length && selectedVersion.length
                  ? `${selectedUser[0].value}/2/${selectedVersion[0].value}`
                  : undefined
              }
              disabled={loading || resQuestions.length === 0}
              title={`Relatório Avaliação 360`}
            />
            <Button
              ml="3"
              variant="outline"
              w="min"
              h="8"
              fontSize="0.75rem"
              type="button"
              leftIcon={<FaPrint size={18} />}
              onClick={onOpen}
              disabled={loading || resQuestions.length === 0}
            >
              Imprimir Relatório
            </Button>
          </Box>
        </Flex>

        <Flex flexDirection="column">
          <Flex gap={'0.625rem'} width="100%" flexWrap={'wrap'}>
            {user.type_account !== 2 && (
              <FormControl width="18.75rem">
                <FormLabel fontSize="0.75rem">Empresa</FormLabel>
                <MultiSelect
                  options={companies}
                  value={selectedCompanies}
                  onChange={(e: Option[]) => {
                    const dif = e.filter((item1) => !selectedCompanies.some((item2) => item1.value === item2.value));

                    setSelectedCompanies(dif);

                    if (e.length) {
                      handleUsers(e[0].value);
                    } else {
                      setUsers([]);
                      setSelectedUser([]);
                      setVersions([]);
                      setSelectedVersion([]);
                    }
                  }}
                  hasSelectAll={false}
                  labelledBy="Select"
                  overrideStrings={{
                    allItemsAreSelected: 'Todos Selecionados',
                    noOptions: 'Sem opções',
                    search: 'Procurar',
                    selectSomeItems: 'Empresas',
                  }}
                  ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
                />
              </FormControl>
            )}
            <FormControl width="18.75rem">
              <FormLabel fontSize="0.75rem">Avaliado</FormLabel>
              <MultiSelect
                options={users}
                value={selectedUser}
                onChange={(e: Option[]) => {
                  const dif = e.filter((item1) => !selectedUser.some((item2) => item1.value === item2.value));
                  setSelectedUser(dif);
                  if (e.length) {
                    handleVersions(dif[0].value);
                  } else {
                    setVersions([]);
                    setSelectedVersion([]);
                  }
                }}
                hasSelectAll={false}
                labelledBy="Select"
                overrideStrings={{
                  allItemsAreSelected: 'Todos Selecionados',
                  noOptions: 'Sem opções',
                  search: 'Procurar',
                  selectSomeItems: 'Avaliados',
                }}
                ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
              />
            </FormControl>
            <FormControl width="18.75rem">
              <FormLabel fontSize="0.75rem">Data</FormLabel>
              <MultiSelect
                options={versions}
                value={selectedVersion}
                onChange={async (e: Option[]) => {
                  const dif = e.filter((item1) => !selectedVersion.some((item2) => item1.value === item2.value));
                  setSelectedVersion(dif);
                  if (!e.length) {
                    setResQuestions([]);
                    setQttAll(0);
                    return;
                  }
                  setLoading(true);
                  const countFetch = await api.get(
                    `tableresponse/count/all/true/${selectedUser[0].value}/${1}/${dif[0].value}`,
                  );
                  const { count } = countFetch.data;
                  if (Number(count) === 0) {
                    setLoading(false);
                    setResQuestions([]);
                    setQttAll(0);
                    return;
                  }
                  setQttAll(Number(count));
                  await handleDetails(selectedUser[0].value, dif[0].value, Number(count));
                  setLoading(false);
                }}
                hasSelectAll={false}
                labelledBy="Select"
                overrideStrings={{
                  allItemsAreSelected: 'Todos Selecionados',
                  noOptions: 'Sem opções',
                  search: 'Procurar',
                  selectSomeItems: 'Datas',
                }}
                ArrowRenderer={() => <RiArrowDropDownLine size="1.25rem" />}
              />
            </FormControl>
          </Flex>

          {loading ? <Loading label="Carregando..." /> : null}

          {resQuestions.length && !loading ? (
            <Flex marginTop={10}>
              <Tabs w="100%" isFitted colorScheme="yellow">
                <TabList>
                  <Tab onClick={() => setQuestion(0)}>Consolidado</Tab>
                  <Tab onClick={() => setQuestion(0)}>Detalhes</Tab>
                </TabList>

                <Flex alignItems="center" p={4}>
                  {questionSelect < 10 && (
                    <>
                      <Text bg="#fdc300" borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
                      <Text fontSize="0.6rem" mr="0.625rem">
                        Pontos positivos
                      </Text>
                      <Text bg="blackAlpha.800" borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
                      <Text fontSize="0.6rem" mr="0.625rem">
                        Pontos negativos
                      </Text>
                      <Text bg="gray.500" borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
                      <Text fontSize="0.6rem" mr="0.625rem">
                        Não sei opinar
                      </Text>
                      <Text bg="#ff9000" borderRadius="50%" w="0.625rem" h="0.625rem" mr="0.3125rem" />
                      <Box w="10rem">
                        <TabPanels m={0} p={0} flexGrow={0} width={10}>
                          <TabPanel m={0} p={0} flexGrow={0} w={'max-content'}>
                            <Text fontSize="0.6rem" mr="0.625rem">
                              Avaliação 360
                            </Text>
                          </TabPanel>
                          <TabPanel m={0} p={0} flexGrow={0} w={'max-content'}>
                            <Text fontSize="0.6rem" mr="0.625rem">
                              Autoavaliação
                            </Text>
                          </TabPanel>
                        </TabPanels>
                      </Box>
                    </>
                  )}
                  <Text ml="auto" fontSize="0.8rem" mr="0.625rem" fontWeight="bold">
                    {qttAll} respostas
                  </Text>
                </Flex>

                <TabPanels>
                  <TabPanel p={0}>
                    <>
                      <Divider marginTop={2} marginBottom={2} orientation="horizontal" />

                      <Box maxW="98%" maxH="38.75rem" marginTop={4}>
                        <Flex>
                          {resQuestions.length
                            ? questionsData.consolidated.map((op, index) => (
                                <Flex
                                  m="1"
                                  key={op.name}
                                  flexDir="column"
                                  justifyContent="center"
                                  alignItems="center"
                                  w="100%"
                                  h="100%"
                                >
                                  <Text
                                    fontSize="0.8rem"
                                    textAlign="center"
                                    h="2.5rem"
                                    w="100%"
                                    fontWeight="bold"
                                    marginBottom={2}
                                  >
                                    {op.name === 'Gestão de Tempo e Execução' ? 'Gestão de Tempo' : op.name}
                                  </Text>

                                  <Box
                                    border={'0.0625rem solid #000'}
                                    paddingLeft={5}
                                    paddingRight={5}
                                    paddingTop={0}
                                    paddingBottom={0}
                                    borderRadius={20}
                                  >
                                    <Text textAlign={'center'} color={'grey'} fontSize={'xs'} paddingTop={1}>
                                      ({resQuestions[index].positiveQty}/
                                      {6 * qttAll - Number(resQuestions[index].neutralQty) * 6})
                                    </Text>
                                    <Flex
                                      w="3.125rem"
                                      h="12.5rem"
                                      direction="column"
                                      justifyContent="end"
                                      alignItems={'center'}
                                    >
                                      <Box
                                        bgColor="#fdc300"
                                        h={`${
                                          parseInt(
                                            String(
                                              (Number(resQuestions[index].positiveQty) /
                                                (6 * qttAll - Number(resQuestions[index].neutralQty) * 6)) *
                                                100 || 0,
                                            ),
                                          ) + 10
                                        }%`}
                                        width={'4rem'}
                                        textAlign="center"
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <Text fontSize="0.75rem" m="auto" fontWeight="bold" color="white">
                                          <>
                                            {parseInt(
                                              String(
                                                (Number(resQuestions[index].positiveQty) /
                                                  (6 * qttAll - Number(resQuestions[index].neutralQty) * 6)) *
                                                  100 || 0,
                                              ),
                                            )}
                                            %
                                          </>
                                        </Text>
                                      </Box>
                                    </Flex>
                                    <Flex w="3.125rem" h="15.625rem" direction="column" alignItems={'center'}>
                                      <Box
                                        bgColor="blackAlpha.800"
                                        h={`${
                                          parseInt(
                                            String(
                                              (Number(resQuestions[index].negativeQty) /
                                                (6 * qttAll - Number(resQuestions[index].neutralQty) * 6)) *
                                                100 || 0,
                                            ),
                                          ) + 5
                                        }%`}
                                        width={'4rem'}
                                        textAlign="center"
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <Text fontSize="0.75rem" m="auto" fontWeight="bold" color="white">
                                          {parseInt(
                                            String(
                                              (Number(resQuestions[index].negativeQty) /
                                                (6 * qttAll - Number(resQuestions[index].neutralQty) * 6)) *
                                                100 || 0,
                                            ),
                                          )}
                                          %
                                        </Text>
                                      </Box>
                                    </Flex>
                                    <Text textAlign={'center'} color={'grey'} fontSize={'xs'} paddingBottom={1}>
                                      ({resQuestions[index].negativeQty}/
                                      {6 * qttAll - Number(resQuestions[index].neutralQty) * 6})
                                    </Text>
                                  </Box>
                                </Flex>
                              ))
                            : null}
                        </Flex>
                      </Box>

                      <Flex direction="column">
                        <Text marginTop="4rem" fontWeight="800" align="center" fontSize="1.25rem">
                          Consolidado 360 graus
                        </Text>
                        <Text
                          as="i"
                          fontWeight="400"
                          align="center"
                          marginTop="0.625rem"
                          marginInline="auto"
                          fontSize="0.875rem"
                          maxWidth="850"
                          color="gray.500"
                        >
                          Barras amarelas representam pontos fortes, enquanto as cinza escuro indicam pontos a
                          desenvolver. Os percentuais dentro das colunas e os valores absolutos nos extremos das barras
                          refletem o grau de concordância entre os avaliadores, com base no total de respostas possíveis
                          (seis positivas e seis negativas por avaliador).
                        </Text>
                      </Flex>
                    </>
                  </TabPanel>
                  <TabPanel p={0} px={4}>
                    <>
                      <Flex alignItems="center" justifyContent="flex-end" userSelect="none">
                        <Box>
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((q) => (
                            <Tag
                              cursor="pointer"
                              boxShadow={
                                questionSelect === q ? 'inset 0 0 0px 1px #ff9000' : 'inset 0 0 0px 1px #718096'
                              }
                              key={q}
                              onClick={() => setQuestion(q)}
                              size="md"
                              m={1}
                              variant="outline"
                            >
                              {q + 1}
                            </Tag>
                          ))}
                        </Box>
                      </Flex>

                      <Divider marginTop={2} marginBottom={2} orientation="horizontal" />

                      <Box maxW="98%" mb={2}>
                        <Flex mt={1}>
                          <Text fontSize="0.75rem" w="60%" fontWeight="bold">
                            {questionSelect + 1}.{questionsData.questions[questionSelect].text}
                          </Text>
                        </Flex>
                        {questionSelect >= 10 ? (
                          <>
                            {resQuestions?.[questionSelect]?.self?.map((response: string, index: number) => (
                              <List m="1" key={index}>
                                <Text fontWeight="bold" fontSize="0.75rem">
                                  {response}
                                </Text>
                              </List>
                            ))}
                            {resQuestions?.[questionSelect]?.evaluators?.map((response: string, index: number) => (
                              <List m="1" key={index}>
                                <Text fontSize="0.75rem">{response}</Text>
                              </List>
                            ))}
                          </>
                        ) : (
                          <>
                            {resQuestions?.[questionSelect]?.positive?.map((op: any) => (
                              <Flex m="1" key={op.value}>
                                <Text fontSize="0.75rem" w="50%">
                                  {op.text}
                                </Text>
                                <FaCheck color={op.selected_auto ? '#ff9000' : 'white'} size={24} />

                                <Box w="50%" mt="auto" mb="auto" ml={1}>
                                  <Tooltip
                                    label={`Respostas: ${op.qtt_response ?? 0}`}
                                    placement="top-end"
                                    background="#ffffff"
                                    fontWeight="bold"
                                    color="black"
                                    border="0.0625rem solid black"
                                  >
                                    <Box
                                      bgColor={op.percent_response === '0%' ? 'white' : '#fdc300'}
                                      h={5}
                                      w={op.percent_response ?? '0%'}
                                      minWidth="1.875rem"
                                    >
                                      <Text
                                        color={op.percent_response === '0%' ? 'black' : 'white'}
                                        mr={1}
                                        fontSize="0.75rem"
                                        textAlign="right"
                                        fontWeight="bold"
                                      >
                                        {op.percent_response}
                                      </Text>
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </Flex>
                            ))}
                            <Divider m={2} />

                            <Flex m="1">
                              <Text fontSize="0.75rem" w="50%">
                                {resQuestions?.[questionSelect]?.neutral[0]?.text}
                              </Text>
                              <FaCheck
                                color={resQuestions?.[questionSelect]?.neutral[0]?.selected_auto ? '#ff9000' : 'white'}
                                size={24}
                              />
                              <Box w="50%" mt="auto" mb="auto" ml={1}>
                                <Tooltip
                                  label={`Respostas: ${resQuestions?.[questionSelect]?.neutral[0].qtt_response ?? 0}`}
                                  placement="top-end"
                                  background="#ffffff"
                                  fontWeight="bold"
                                  color="black"
                                  border="0.0625rem solid black"
                                >
                                  <Box
                                    bgColor={
                                      resQuestions?.[questionSelect]?.neutral[0]?.percent_response === '0%'
                                        ? 'white'
                                        : 'gray.400'
                                    }
                                    h={5}
                                    w={resQuestions?.[questionSelect]?.neutral[0]?.percent_response}
                                    minWidth="1.875rem"
                                  >
                                    <Text
                                      color={
                                        resQuestions?.[questionSelect]?.neutral[0]?.percent_response === '0%'
                                          ? 'black'
                                          : 'white'
                                      }
                                      mr={1}
                                      fontSize="0.75rem"
                                      textAlign="right"
                                      fontWeight="bold"
                                    >
                                      {resQuestions?.[questionSelect]?.neutral[0]?.percent_response}
                                    </Text>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Flex>

                            <Divider m={2} />

                            {resQuestions?.[questionSelect]?.negative?.map((op: any) => (
                              <Flex m="1" key={op.value}>
                                <Text fontSize="0.75rem" w="50%">
                                  {op.text}
                                </Text>
                                <FaCheck color={op.selected_auto ? '#ff9000' : 'white'} size={24} />

                                <Box w="50%" mt="auto" mb="auto" ml={1}>
                                  <Tooltip
                                    label={`Respostas: ${op.qtt_response ?? 0}`}
                                    placement="top-end"
                                    background="#ffffff"
                                    fontWeight="bold"
                                    color="black"
                                    border="0.0625rem solid black"
                                  >
                                    <Box
                                      bgColor={op.percent_response === '0%' ? 'white' : 'blackAlpha.800'}
                                      h={5}
                                      w={op.percent_response}
                                      minWidth="1.875rem"
                                    >
                                      <Text
                                        color={op.percent_response === '0%' ? 'black' : 'white'}
                                        mr={1}
                                        fontSize="0.75rem"
                                        textAlign="right"
                                        fontWeight="bold"
                                      >
                                        {op.percent_response}
                                      </Text>
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </Flex>
                            ))}
                          </>
                        )}
                      </Box>
                      {questionSelect >= 10 ? null : (
                        <Flex direction="column">
                          <Text marginTop="4rem" fontWeight="800" align="center" fontSize="1.25rem">
                            Mapeamento 360 graus
                          </Text>
                          <Text
                            as="i"
                            fontWeight="400"
                            align="center"
                            marginTop="0.625rem"
                            marginInline="auto"
                            fontSize="0.875rem"
                            maxWidth="850"
                            color="gray.500"
                          >
                            Barras amarelas na parte superior representam afirmações positivas, enquanto barras cinza
                            escuro na inferior mostram as negativas. Percentuais de 0 a 100% em cada barra refletem a
                            concordância dos avaliadores. Cinza claro para &quot;não sei avaliar&quot; indica
                            abstenções, e um marcador alaranjado destaca a autoavaliação.
                          </Text>
                        </Flex>
                      )}
                    </>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          ) : null}
        </Flex>
      </Flex>

      <ReportDrawer title={`RELATÓRIO AVALIAÇÃO 360°`} isOpen={isOpen} onClose={onClose}>
        <DocumentComponent
          evaluatedName={selectedUser?.[0]?.label || ''}
          company={selectedCompanies?.[0]?.label || ''}
          year={getYear(Date.now())}
          month={String(
            format(Date.now(), 'MMMM', {
              locale: ptBR,
            }),
          ).toUpperCase()}
          questionsResponse={resQuestions}
          count={qttAll || 1}
        />
      </ReportDrawer>
    </>
  );
};

export default BarChart360;
