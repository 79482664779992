import { Flex, Text } from '@chakra-ui/react'
import { SimpleScannerDashboard } from 'layouts/dash/types'
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import api from 'services/api'

const formatter = (value: any): string => {
  const text = value === 0 ? '' : `${value} %`
  return text
}

const options: any = {
  responsive: true,
  indexAxis: 'y',
  layout: {
    padding: {
      top: 40,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false,
      text: 'Avaliação Motivacional de Objetivos.',
      position: 'bottom',
      color: 'black',
      font: {
        weight: '800',
        size: '20px',
      },
    },
    legend: {
      display: false,
    },
    datalabels: {
      labels: {
        value: {
          formatter,
          anchor: 'end',
          align: 'left',
          color: 'white',
          font: {
            weight: '700',
            size: '16px',
          },
        },
      },
    },
  },
  scales: {
    y: {
      grid: {
        display: false,
      },
    },
    x: {
      suggestedMax: 100,
      grid: {
        display: true,
        borderWidth: 0,
      },
      ticks: {
        borderWidth: 2,
        stepSize: 20,
      },
      title: {
        display: false,
      },
    },
  },
}

export default function MotiChart(props: SimpleScannerDashboard) {
  const { userId, chartRefs, scanner = false } = props

  const [data, setData] = useState<number[]>([])

  useEffect(() => {
    if (!userId || userId === '') return setData([])

    api
      .post(`tableresponse/evaluations/`, { evaluations: [userId] })
      .then(({ data }) => setData(data))
      .catch(() => null)
  }, [userId])

  if (!userId || userId === '' || !data.length) return null

  return (
    <Flex width="100%" justifyContent="center" alignItems="center" flexDirection="column" ref={chartRefs?.[0]}>
      <Flex maxWidth="50rem" width="100%" height="25rem" flexDirection="column">
        <Bar
          data={{
            labels: [
              'Motivação intrínseca',
              'Regulação integrada',
              'Regulação identificada',
              'Regulação introjetada',
              'Regulação externa',
            ],
            datasets: [{ data, backgroundColor: '#F3B507' }],
          }}
          options={options}
        />
      </Flex>
      <Flex flexDirection="column" alignItems="center">
        <Text fontSize="1.25rem" fontWeight="700" marginTop="1.25rem" textAlign="center">
          Resultado da Avaliação Motivacional de Objetivos
        </Text>
        {!scanner ? (
          <Text
            as="i"
            fontWeight="400"
            align="center"
            marginTop="0.625rem"
            fontSize="0.875rem"
            maxWidth="500"
            color="gray.500"
          >
            Os resultados são apresentados em valores percentuais. As porcentagens mais altas indicam o tipo de
            motivação predominante. A soma de todos os percentuais equivale a 100%.
          </Text>
        ) : null}
      </Flex>
    </Flex>
  )
}
