import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import { SimpleDashboardConsolidated } from 'layouts/dash/types'
import React, { useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import api from 'services/api'

interface UserCommunicationResponse {
  name: string
  user_id: string
  communication: Array<Array<string | number>>
}

interface CommunicationData {
  [key: string]: number
}

function ComunicacaoConsolidated(props: SimpleDashboardConsolidated): JSX.Element {
  const { evaluations, chartRefs, responses, evaluationData } = props
  const toast = useToast()
  const [communicationData, setCommunicationData] = useState<CommunicationData[]>()
  const [userData, setUserData] = useState<UserCommunicationResponse[]>([])

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      if (evaluationData) return
      if (!evaluations || !evaluations.length) return
      try {
        const { data } = await api.post(`tableresponse/comunicacao/consolidated`, {
          evaluations: evaluations,
        })
        const { consolidated, users } = data
        setCommunicationData(consolidated)
        responses.current = users
        setUserData(users)
      } catch (err) {
        toast({
          status: 'error',
          title: 'Não foi possível achar as informações',
        })
        setCommunicationData(undefined)
      }
    }
    fetch()
  }, [evaluations])

  useEffect(() => {
    if (evaluationData) {
      const { consolidated, users } = evaluationData
      setCommunicationData(consolidated)
      responses.current = users
      setUserData(users)
    }
  }, [evaluationData])

  const options: any = useMemo(() => {
    return {
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          top: 40,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          labels: {
            value: {
              formatter: (value: any) => {
                return [`${((value / userData.length) * 100 || 0).toFixed(0)}%`, value]
              },
              anchor: 'end',
              align: 'top',
              textAlign: 'center',
              offset: -5,
              color: 'black',
              font: {
                weight: 500,
                size: 13,
              },
              // anchor: 'end',
              // align: 'top',
              // offset: -5,
              // color: 'black',
              // font: {
              //   weight: '700',
              //   size: '1.375rem'
              // }
            },
          },
        },
      },
      scales: {
        y: {
          display: false,
        },
        x: {
          grid: {
            display: false,
            borderWidth: 2,
            borderColor: '#c2bfbe',
          },
        },
      },
    }
  }, [userData])

  if (!communicationData || !userData.length) {
    return <></>
  }

  return (
    <Flex width="100%" height="100%" justifyContent="space-evenly">
      <Box margin="auto 0" height="80%">
        <Flex flexDirection="column" alignItems="center" ref={chartRefs?.[0]} padding="0.625rem" height="100%">
          <Text fontSize="1.25rem" fontWeight="700" textAlign="center">
            Resultado Consolidado do teste Tratamento de Objeção
          </Text>
          <Text fontSize="0.875rem" fontWeight="700" textAlign="center">
            Colaboradores selecionados: {userData.length}
          </Text>
          <Box height="100%" width="100%">
            <Bar
              width={'100%'}
              height="100%"
              data={{
                labels: ['Divergente', 'Convergente', 'Conflitante', 'Concorrente'],
                datasets: [
                  {
                    label: 'Primário',
                    data: [
                      communicationData[0].Divergente,
                      communicationData[0].Convergente,
                      communicationData[0].Conflitante,
                      communicationData[0].Concorrente,
                    ],
                    backgroundColor: ['#81e7dc', '#f0dd90', '#f59c62', '#89d1f7'],
                    minBarLength: 7,
                  },
                  {
                    label: 'Secundário',
                    data: [
                      communicationData[1].Divergente,
                      communicationData[1].Convergente,
                      communicationData[1].Conflitante,
                      communicationData[1].Concorrente,
                    ],
                    backgroundColor: ['#81e7dc60', '#f0dd9060', '#f59c6260', '#89d1f760'],
                    minBarLength: 7,
                  },
                ],
              }}
              options={options}
            />
          </Box>

          <Text
            as="i"
            fontWeight="400"
            align="center"
            marginTop="0.625rem"
            fontSize="0.875rem"
            maxWidth="500"
            color="gray.500"
          >
            Os números em cada coluna indicam a quantidade de pessoas com cada perfil, em valores percentuais e
            absolutos. Em cada tipo de perfil, temos duas colunas, sendo a coluna 1, com cor mais intensa, referente ao
            perfil primário, e a coluna 2, com cor mais suave, ao perfil secundário. O melhor perfil é o convergente
          </Text>
        </Flex>
      </Box>
      {!evaluationData && (
        <Box
          boxShadow={'#7a7a7a 0rem 0rem 0.0625rem'}
          maxWidth="37.5rem"
          borderRadius={'0.3125rem'}
          overflowY="auto"
          maxHeight="min(37.5rem, 100%)"
          alignSelf="flex-start"
          marginTop="auto"
          marginBottom="auto"
          style={{ scrollbarWidth: 'thin' }}
        >
          <Table variant="striped">
            <Thead position="sticky" top="0">
              <Tr bgColor={'white'}>
                <Th>Nome</Th>
                <Th>Primário</Th>
                <Th>Secundário</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userData.map((user, index) => (
                <Tr key={index}>
                  <Td>{user.name}</Td>
                  <Td>{user.communication[0][0]}</Td>
                  <Td>{user.communication[1][1] > 0 ? user.communication[1][0] : ''}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Flex>
  )
}

export default ComunicacaoConsolidated
