import { FaBuilding, FaChartArea, FaDatabase, FaMailBulk, FaUser } from 'react-icons/fa';

export const adminMenu = [
  { name: 'Cadastro', icon: <FaBuilding />, route: '/company', subMenu: [] },
  { name: 'Treinamentos', icon: <FaUser />, route: '/courses', subMenu: [] },
  { name: 'Filas', icon: <FaMailBulk />, route: '/queues', subMenu: [] },
  { name: 'Gestão de dados', icon: <FaDatabase />, route: '/gestaodados', subMenu: [] },
  {
    name: 'Mapeamentos de perfil',
    icon: <FaBuilding />,
    route: '',
    subMenu: [
      { route: '/dash/-2', name: 'Scanner Consolidado', icon: <FaChartArea /> },
      { route: '/dash/-1', name: 'Scanner', icon: <FaChartArea /> },
      { route: '/dash/3', name: 'Alpha', icon: <FaChartArea /> },
      { route: '/dash/9', name: 'Comunicação', icon: <FaChartArea /> },
      { route: '/dash/6', name: 'Motivacional de Objetivos', icon: <FaChartArea /> },
      { route: '/dash/5', name: 'Temperamentos', icon: <FaChartArea /> },
      { route: '/dash/4', name: 'VIA', icon: <FaChartArea /> },
    ],
  },
  {
    name: 'Mapeamentos de área',
    icon: <FaBuilding />,
    route: '',
    subMenu: [
      { route: '/dash/8', name: 'Mapa Operacional Área', icon: <FaChartArea /> },
      { route: '/dash/10', name: 'Mapa Tático Área', icon: <FaChartArea /> },
      { route: '/dash/30', name: 'Mapa Estratégico', icon: <FaChartArea /> },
      {
        route: '/dash/7',
        name: 'Avaliação de Desempenho da Equipe',
        icon: <FaChartArea />,
      },
      {
        route: '/dash/14',
        name: 'Mapeamento estratégico e tático do RH',
        icon: <FaChartArea />,
      },
    ],
  },
  {
    name: 'Mapeamento 360 graus',
    icon: <FaBuilding />,
    route: '',
    subMenu: [
      { route: '/dash/2', name: 'Avaliação 360', icon: <FaChartArea /> },
      {
        route: '/avaliacao360porentrevista',
        name: 'Avaliação 360 Por Entrevista',
        icon: <FaChartArea />,
      },
    ],
  },
  { name: 'Análise das variáveis críticas da mudança', icon: <FaChartArea />, route: '/dash/12', subMenu: [] },
  { name: 'Escala de estresse percebido', icon: <FaChartArea />, route: '/dash/13', subMenu: [] },
];

export const humanResourcesMenu = [
  { name: 'Cadastrados', icon: <FaUser />, route: '/users', subMenu: [] },
  { name: 'Gestão de dados', icon: <FaDatabase />, route: '/gestaodados', subMenu: [] },
  {
    name: 'Mapeamentos de perfil',
    icon: <FaBuilding />,
    route: '',
    subMenu: [
      { route: '/dash/-2', name: 'Scanner Consolidado', icon: <FaChartArea /> },
      { route: '/dash/-1', name: 'Scanner', icon: <FaChartArea /> },
      { route: '/dash/3', name: 'Alpha', icon: <FaChartArea /> },
      { route: '/dash/9', name: 'Comunicação', icon: <FaChartArea /> },
      { route: '/dash/6', name: 'Motivacional de Objetivos', icon: <FaChartArea /> },
      { route: '/dash/5', name: 'Temperamentos', icon: <FaChartArea /> },
      { route: '/dash/4', name: 'VIA', icon: <FaChartArea /> },
    ],
  },
  {
    name: 'Mapeamentos de área',
    icon: <FaBuilding />,
    route: '',
    subMenu: [
      { route: '/dash/8', name: 'Mapa Operacional Área', icon: <FaChartArea /> },
      { route: '/dash/10', name: 'Mapa Tático Área', icon: <FaChartArea /> },
      { route: '/dash/30', name: 'Mapa Estratégico', icon: <FaChartArea /> },
      {
        route: '/dash/7',
        name: 'Avaliação de Desempenho da Equipe',
        icon: <FaChartArea />,
      },
    ],
  },
  {
    name: 'Mapeamento 360 graus',
    icon: <FaBuilding />,
    route: '',
    subMenu: [
      { route: '/dash/2', name: 'Avaliação 360', icon: <FaChartArea /> },
      {
        route: '/avaliacao360porentrevista',
        name: 'Avaliação 360 Por Entrevista',
        icon: <FaChartArea />,
      },
    ],
  },
  { name: 'Análise das variáveis críticas da mudança', icon: <FaChartArea />, route: '/dash/12', subMenu: [] },
];
