import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import Logo from 'assets/logowhite.png'
import ProgressCounter from 'components/Questions/ProgressCounter'
import QuestionComp from 'components/Questions/QuestionComp'
import useEvaluation from 'hooks/useEvaluation'
import { FieldError, SubmitErrorHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import questions from './questions.json'

interface IParamsProps {
  id: string
}

type FormAnaliseCritica = Record<number, string[][]>

const getAnswers = (data: FormAnaliseCritica) => {
  const final: { question: string; response: string }[] = []
  const pages = Object.values(data)

  pages.forEach((page, pageIndex) => {
    page.forEach((question, questionIndex) => {
      const questionNumber = `${pageIndex}.${questionIndex}`
      question.forEach((answer, answerIndex) => {
        if (!answer) return
        if (!answerIndex) {
          final.push({ question: `${questionNumber}`, response: answer })
        } else {
          final.push({ question: `${questionNumber}.1`, response: answer })
        }
      })
    })
  })
  return final
}

export default function QuestionEstresse() {
  const { id } = useParams<IParamsProps>()
  const { handleSubmit, control, watch } = useForm<FormAnaliseCritica>({ shouldFocusError: true })
  const { registerResponses } = useEvaluation({ id })

  async function onSubmit(data: FormAnaliseCritica) {
    const answers = getAnswers(data)
    await registerResponses(answers)
  }

  const handleInvalid: SubmitErrorHandler<FormAnaliseCritica> = (evt) => {
    const pages = Object.values(evt) as unknown as FieldError[][]
    pages.forEach((page) => {
      if (!page) return
      page.forEach((question: FieldError) => {
        if (!question) return
        toast.error(question.message)
      })
    })
  }
  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSubmit, handleInvalid)}
      w="100%"
      h="100vh"
      alignItems="center"
      flexDir="column"
      overflow="hidden"
    >
      <Flex overflowY="auto" overflowX="hidden" w="100%" flexDir="column" alignItems="center">
        <Box maxWidth={['100%', '100%', '62.5rem']}>
          <Flex
            justify="center"
            w={['100%', '100%', '62.5rem']}
            bg="#f8bb03"
            flexDirection={['column']}
            padding="1.25rem"
          >
            <Flex
              justify="space-around"
              align="center"
              w={['100%', '100%', '62.5rem']}
              flexDirection={['column', 'row']}
            >
              <Image w={['11.25rem', '14.375rem']} h={['8.125rem', '9.375rem']} src={Logo} alt="Logo White" />
              <Text color="#FFF" fontSize={['1.5rem', '2.3rem']}>
                Escala de estresse percebido
              </Text>
            </Flex>
            <Box marginX="2.5rem" marginTop="1.25rem">
              <Text align="left" maxWidth="43.75rem" fontWeight="600">
                As questões nesta escala perguntam sobre seus sentimentos e pensamentos durante o último mês. Em cada
                caso, será pedido para você indicar o quão frequentemente você tem se sentido de uma determinada
                maneira. Embora algumas das perguntas sejam similares, há diferenças entre elas e você deve analisar
                cada uma como uma pergunta separada. A melhor abordagem é responder a cada pergunta razoavelmente
                rápido. Isto é, não tente contar o número de vezes que você se sentiu de uma maneira particular, mas
                indique a alternativa que lhe pareça como uma estimativa razoável. Para cada pergunta, escolha as
                seguintes alternativas:
              </Text>
            </Box>
          </Flex>
          <Flex margin={['0.625rem 2.5rem', '0.625rem 3.75rem']}>
            <Text color="red">*</Text>
            <Text>Obrigatória</Text>
          </Flex>
          <Box position="relative">
            {questions[0].questions.map(({ question }, index) => {
              return (
                <QuestionComp
                  key={question}
                  question={{ text: question }}
                  index={index}
                  page={0}
                  control={control}
                  options={['Nunca', 'Quase nunca', 'Às vezes', 'Quase sempre', 'Sempre']}
                  previousText="Neste último mês, com que frequência..."
                />
              )
            })}
          </Box>
        </Box>
      </Flex>
      <ProgressCounter
        maxWidth={['100%', '100%', '62.5rem']}
        width={'100%'}
        text="Respondidas"
        counter={watch()?.[0]?.reduce((acc, curr) => acc + (curr.length ? 1 : 0), 0) ?? 0}
        total={questions[0].questions.length}
        bg="white"
      >
        <Button type="submit" w="10rem" bg="#f8bb03" color="#fff" fontWeight="500" fontSize="1.3rem">
          {0 < questions.length - 1 ? 'Avançar' : 'Enviar'}
        </Button>
      </ProgressCounter>
    </Flex>
  )
}
