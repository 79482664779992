import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { ArcElement, Chart, Legend, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { SimpleDashboardConsolidated } from 'layouts/dash/types'
import React, { useEffect, useMemo, useState } from 'react'
import { Bar, Pie } from 'react-chartjs-2'
import api from 'services/api'

const DoughnutLabelPlugin: any = [
  {
    id: 'DoughnutLabelPlugin',
    afterDraw: (chart: any) => {
      const {
        ctx,
        chartArea: { width },
      } = chart
      chart.data.datasets.forEach((dataset: any, i: any) => {
        chart.getDatasetMeta(i).data.forEach((datapoint: any, index: any) => {
          const totalValue = chart.data.datasets[0].data.reduce((p: number, c: number) => p + c, 0)
          const actualValue = chart.data.datasets[0].data[index]

          if (actualValue > 0) {
            const angle = (datapoint.startAngle + datapoint.endAngle) / 2
            const innerDistance = (datapoint.outerRadius - datapoint.innerRadius) / 2

            const { x, y } = datapoint.tooltipPosition()
            const halfw = chart.width / 2

            const startx = x + Math.cos(angle) * innerDistance
            const starty = y + Math.sin(angle) * innerDistance
            const xLine = x + Math.cos(angle) * 35
            const yLine = y + Math.sin(angle) * 35

            const extraLine = startx >= halfw ? 8 : -8

            ctx.beginPath()
            ctx.moveTo(startx, starty)
            ctx.lineTo(xLine, yLine)
            ctx.lineTo(xLine + extraLine, yLine)
            ctx.strokeStyle = '#bfbebf'
            ctx.stroke()

            ctx.font = '0.8125rem Arial'

            const textPos = startx >= halfw ? 'left' : 'right'
            ctx.textAlign = textPos
            ctx.textBaseline = 'middle'

            ctx.fillText(`${((actualValue * 100) / totalValue).toFixed()}%`, xLine + extraLine, yLine)
          }
        })
      })
    },
  },
]

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels)

enum AlphaTypes {
  'Visionário',
  'Executor',
  'Estrategista',
  'Comandante',
}

interface AlphaData {
  name: string
  user_id: string
  answers: AlphaTypes[]
  alpha: boolean
}

const options: any = {
  responsive: true,
  layout: {
    padding: 45,
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
}

const defaultAlphaStrengths: { [key: string]: number } = {
  Comandante: 0,
  Visionário: 0,
  Estrategista: 0,
  Executor: 0,
}

function AlphaConsolidated(props: SimpleDashboardConsolidated) {
  const { evaluations, responses, chartRefs, evaluationData } = props
  const [alphaData, setAlphaData] = useState<AlphaData[]>([])
  const pieData = useMemo(() => {
    const alphaUsers = alphaData.filter((user) => user.alpha)
    const alpha = {
      labels: ['NÃO ALPHA', 'ALPHA'],
      datasets: [
        {
          label: 'Entrevistados',
          data: [alphaData.length - alphaUsers.length, alphaUsers.length],
          backgroundColor: ['#585858', '#ffc734'],
          hoverOffset: 10,
          borderColor: ['#ffffff'],
          borderWidth: 1,
        },
      ],
    }

    const primaryQuantities = { ...defaultAlphaStrengths }
    const secondaryQuantities = { ...defaultAlphaStrengths }
    alphaData.forEach((user) => {
      if (user.answers[0] && user.answers[1]) {
        primaryQuantities[user.answers[0]] += 1
        secondaryQuantities[user.answers[1]] += 1
      }
    })

    const barData = {
      labels: Object.keys(primaryQuantities),
      datasets: [
        {
          label: 'Primário',
          data: Object.values(primaryQuantities),
          backgroundColor: ['#ffc734'],
          minBarLength: 7,
        },
        {
          label: 'Secundário',
          data: Object.values(secondaryQuantities),
          backgroundColor: ['#585858'],
          minBarLength: 7,
        },
      ],
    }

    return { alpha, barData }
  }, [alphaData])

  const barOptions: any = useMemo(() => {
    return {
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          top: 40,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (t: any) => {
              const total = t.dataset.data.reduce((acc: number, a: number) => acc + a, 0)
              return `${((t.raw / total) * 100).toFixed(1)}%`
            },
          },
        },
        datalabels: {
          labels: {
            value: {
              formatter: (value: any) => {
                return [`${((value / alphaData.length) * 100 || 0).toFixed(0)}%`, value]
              },
              anchor: 'end',
              align: 'top',
              textAlign: 'center',
              offset: -5,
              color: 'black',
              font: {
                weight: 500,
                size: 13,
              },
            },
          },
        },
      },
      scales: {
        y: {
          display: false,
        },
        x: {
          grid: {
            display: false,
            borderWidth: 2,
            borderColor: '#c2bfbe',
          },
        },
      },
    }
  }, [alphaData])

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      if (evaluationData) return
      if (!evaluations || !evaluations.length) return
      if (!evaluations.length) {
        setAlphaData([])
        return
      }
      const { data } = await api.post(`tableresponse/alpha/consolidated`, {
        evaluations: evaluations,
      })
      responses.current = data
      setAlphaData(data)
    }
    fetch()
  }, [evaluations])

  useEffect(() => {
    if (evaluationData) {
      responses.current = evaluationData
      setAlphaData(evaluationData)
    }
  }, [evaluationData])

  if (!alphaData.length) return null

  return (
    <Flex width="100%" height="100%" justifyContent="space-evenly">
      <Flex flexDirection="column" marginTop="auto" marginBottom="auto">
        <Flex flexDirection="column" alignItems="center" ref={chartRefs?.[0]} padding="0.625rem">
          <Text fontSize="1.25rem" fontWeight="700" textAlign="center">
            Resultado Consolidado do teste Alpha
          </Text>
          <Text fontSize="0.875rem" fontWeight="700" textAlign="center">
            Colaboradores selecionados: {alphaData.length}
          </Text>
          <Flex mt="1.25rem">
            <Flex flexDirection="column" alignItems="center" width={200}>
              <Text fontSize="0.875rem" fontWeight="400" textAlign="center" maxWidth="9.375rem">
                Gráfico 1: Distribuição de Perfis Alpha e Não Alpha
              </Text>
              <Pie data={pieData.alpha} options={options} plugins={DoughnutLabelPlugin} width={200} height={200} />
              <Flex fontWeight="700" justifyContent="center" alignItems={'center'} gap="0.3125rem" color="gray.600">
                <Box bg="#ffc734" border="0.0625rem solid #9f9c9b" w="1.5625rem" h={'0.8125rem'} />
                <Text fontSize="0.75rem" width={'5rem'}>
                  ALPHA
                </Text>
              </Flex>
              <Flex fontWeight="700" justifyContent="center" alignItems={'center'} gap="0.3125rem" color="gray.600">
                <Box bg="#4c4c4c" border="0.0625rem solid #9f9c9b" w="1.5625rem" h={'0.8125rem'} />
                <Text fontSize="0.75rem" width={'5rem'}>
                  NÃO ALPHA
                </Text>
              </Flex>
              <Text fontSize="0.875rem" fontWeight="400" color={'gray.500'} as="i" textAlign="center" mt="0.625rem">
                Fatia amarela mostra percentual de pessoas com perfil alpha, enquanto fatia cinza mostra percentual de
                não alphas. A soma de todos os percentuais equivale a 100%
              </Text>
            </Flex>

            <Box
              width={400}
              maxHeight={275}
              paddingBottom={'1rem'}
              marginLeft={'1rem'}
              display="flex"
              flexDirection="column"
              alignItems="center"
              paddingX="1.25rem"
            >
              <Text fontSize="0.875rem" fontWeight="400" textAlign="center" maxWidth="15.625rem">
                Gráfico 2: Resultado Consolidado dos Subtipos Alpha
              </Text>
              <Bar width={'100%'} data={pieData.barData} options={barOptions} />
              <Text fontSize="0.875rem" fontWeight="400" color={'gray.500'} textAlign="center" as="i" mt="0.625rem">
                Os números em cada coluna indicam a quantidade de pessoas com cada perfil, em valores percentuais e
                absolutos. Cada subtipo (Comandante, Visionário, Estrategista e Executor) é representado por duas
                colunas: a primeira, em amarelo, identifica o perfil principal, e a segunda, em cinza, evidencia o
                perfil secundário.
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Flex flexDirection="column" alignItems="center"></Flex>
      </Flex>
      {!evaluationData && (
        <Flex
          boxShadow={'#7a7a7a 0rem 0rem 0.0625rem'}
          maxWidth="37.5rem"
          borderRadius={'0.3125rem'}
          overflowY="auto"
          maxHeight="min(37.5rem, 100%)"
          alignSelf="flex-start"
          marginTop="auto"
          marginBottom="auto"
          style={{ scrollbarWidth: 'thin' }}
        >
          <Table variant="striped">
            <Thead position="sticky" top="0">
              <Tr bgColor={'white'}>
                <Th>Nome</Th>
                <Th>Alpha</Th>
                <Th>Primário</Th>
                <Th>Secundário</Th>
              </Tr>
            </Thead>
            <Tbody>
              {alphaData.map((user, index) => (
                <Tr key={index}>
                  <Td>{user.name}</Td>
                  <Td>{user.alpha ? 'Alpha' : 'Não alpha'}</Td>
                  <Td>{user.answers[0]}</Td>
                  <Td>{user.answers[1]}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      )}
    </Flex>
  )
}

export default AlphaConsolidated
